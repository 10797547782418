<template>
    <van-cell-group title="绑定捡配订单" inset>
    <van-cell>

          <van-field
                  v-model="code"
                  name="code"
                  label="客户订单"
                  placeholder="请扫描客户订单"
                  required
                  :rules="[{ required: true, message: '请扫描客户订单' }]"
                  @focus="this.focusModel= 'code'"
          ></van-field>
      <van-button round block type="primary" @click="this.show1 = true,lastShow='code'" style="height: 5.33333vw">
        扫描二维码
      </van-button>
    </van-cell>

      <van-field
          v-model="pickOrderCode"
          name="pickOrderCode"
          label="拣配单"
      ></van-field>

      <van-cell>
        <van-field
                v-model="packageCode"
                name="packageCode"
                label="箱号"
                placeholder="请扫描物料包装"
                required
                :rules="[{ required: true, message: '请扫描物料包装' }]"
                @focus="this.focusModel= 'packageCode'"
        ></van-field>
      <van-button round block type="primary" @click="this.show1= true,lastShow='packageCode'" style="height: 5.33333vw">
        扫描二维码
      </van-button>
      </van-cell>

      <QRReader
          v-model:show="show1"
          @onRead="onRead1"
      />


      <div style="margin: 1.6vw;">
            <van-button round block type="primary" @click="submitReceive()" style="height: 8vw">
                提交
            </van-button>
        </div>
        <van-field
                v-model="info"
                name="info"
                label="信息"
        ></van-field>
        <div style="margin: 2.66667vw;">
          <van-row  style="margin-top: 2.66667vw" >
            <van-col span="10">
              <van-button round style="width: 42.66667vw;height: 8vw" block type="primary" @click="scanCheck()">
                核验
              </van-button>
            </van-col>
            <van-col span="3"></van-col>
            <van-col span="10">
              <van-button round style="width: 42.66667vw;height: 8vw" block type="success" @click="onPickOrderOutStockConfirm(this.pickOrderOutStockId)">
                刷新
              </van-button>
            </van-col>
          </van-row>
        </div>
    </van-cell-group>

    <van-cell-group title="物料列表" inset>
        <van-tabs v-model:active="active" type="card">
            <van-tab title="捡配清单">
                <van-cell-group inset title="">
                    <ListCard
                            v-for="item in dataList"
                            :key="item.id"
                            :id="item.id"
                    >
                        <div class="item">
                            <div class="fs16">
                                <div class="tags fs16">{{'物料名称：' + item['materialName']}}</div>
                                <div class="tags fs16">{{'物料编码：' + item['materialCode']}}</div>
                                <div class="tags fs16">{{'批次：' + item['batchNum']}}</div>
                                <div class="tags fs16">{{'PD单号：' + item['pdCode']}}</div>
                                <div class="tags fs16">{{'包装号：' + item['packCode']}}</div>
                                <div class="tags fs16">{{'需要数量：' + item['deliveryQty']}}</div>
                                <div class="tags fs16">{{'已扫数量：' + item['deliveryQtyGenerated']}}</div>
                            </div>
                        </div>
                    </ListCard>
                    </van-cell-group >

            </van-tab>
            <van-tab title="已捡配物料">
                <van-cell-group inset title="">
                    <ListCard
                            v-for="item in dataList1"
                            :key="item.id"
                            :id="item.id"
                    >
                        <div class="item">
                            <div class="fs12">
                                <div class="tags fs16">{{'物料名称：' + item['materialName']}}</div>
                                <div class="tags fs16">{{'物料编码：' + item['materialCode']}}</div>
                                <div class="tags fs16">{{'批次：' + item['batchNum']}}</div>
                                <div class="tags fs16">{{'PD单号：' + item['pdCode']}}</div>
                                <div class="tags fs16">{{'包装号：' + item['packCode']}}</div>
                                <div class="tags fs16">{{'需要数量：' + item['deliveryQty']}}</div>
                                <div class="tags fs16">{{'已扫数量：' + item['deliveryQtyGenerated']}}</div>
                            </div>
                        </div>
                    </ListCard>
                </van-cell-group >

            </van-tab>
        </van-tabs>
    </van-cell-group>
    <LocationScanWin
            v-model:show="showEdit"
            ref="scanWin"
            @onConfirm="confirmEdit"
            @onfocus="onfocus"

    />
</template>

<script>
  import QRReader from "@/components/QRReader.vue";

    import Picker from "../../components/Picker";
    import LocationScanWin from "../pickOrder/LocationScanWin";
    import ListCard from "../../components/ListCard";
    import {Toast} from "vant";

    export default {
        name: "ScanPick",
        components: {LocationScanWin,ListCard,QRReader},
        props: {

        },
        data() {
            return {
                show1:false,
                lastShow:"",
                code: '',
                pickOrderOutStockId: '',
                pickOrderId: '',
                pickOrderCode: '',
                outStockCode: '',
                receiptOrderID: '',
                packageCode: '',
                focusModel: '',
                active:'',
                info: '',
                wareHouseAreaId: '',
                wareHouseAreaName: '',
                wareHouseLocationId: '',
                wareHouseLocationName: '',
                dataList: [],
                dataList1: [],
                showEdit: false ,
               defaultLocationCode: "" ,
            }
        },
        created() {
          if (!this.$sapi.isLogin()) {
            this.$sapi.handleTokenError();
            return;
          }
          this.getScanCode();
          this.getOverallAituation();
        },
        methods: {
          onfocus(value) {
            this.show1 = true;
            this.lastShow = "location"
          },
          refreshGrid() {

          },
          onRead1(text) {
            var me = this;
            if (this.lastShow === "code") {
              this.code = text;
              var option1 = {
                portName: 'fr.order.out.PickOrder.scanPickOrderOutStock',
                data: {'code': me.code},
                needLoading: false,
                successCallback: function (data) {

                  me.pickOrderOutStockId = data.data.pickOrderOutStockId;
                  me.pickOrderId = data.data.pickOrderId;
                  me.pickOrderCode = data.data.pickOrderCode;

                  me.info = data.data.info;
                  //捡配完成
                  me.dataList1 = data.data.commuDicGeneratedList;
                  //未捡配
                  me.dataList = data.data.commuDicNotGeneratedList;
                }
              };
              this.$sapi.callPort(option1);
            } else if (this.lastShow === "packageCode") {
              this.packageCode = text;
              var option = {
                portName: 'fr.order.out.PickOrder.scanPickOrderPackageCode',
                data: {'packageCode': me.packageCode, 'pickOrderOutStockId': me.pickOrderOutStockId},
                needLoading: false,
                successTone: true,
                failTone: true,
                successCallback: function (data) {
                  var errorMessage = data.data.errorMessage;
                  if (!_.isEmpty(errorMessage)) {
                    var checkPassMsg = errorMessage + '   是否强行捡配？';
                    me.$dialog.confirm({
                      title: '',
                      message: checkPassMsg,
                    }).then(() => {
                      var option1 = {
                        portName: 'fr.order.out.PickOrder.scanPickOrderPackageCode',
                        data: {
                          'packageCode': me.packageCode,
                          'pickOrderOutStockId': me.pickOrderOutStockId,
                          'isForcedMatching': true
                        },
                        needLoading: false,
                        successTone: true,
                        failTone: true,
                        successCallback: function (data) {
                          me.info = data.data.info;
                          // //捡配完成
                          // me.dataList1 = data.data.commuDicGeneratedList;
                          // //未捡配
                          // me.dataList = data.data.commuDicNotGeneratedList;
                        }
                      };
                      me.$sapi.callPort(option1);
                    });
                    return;
                  } else {
                    me.info = data.data.info;
                    //捡配完成
                    me.dataList1 = data.data.commuDicGeneratedList;
                    //未捡配
                    me.dataList = data.data.commuDicNotGeneratedList;
                  }
                }
              };
              this.$sapi.callPort(option);
            } else {
              me.$refs.scanWin.location = text;
            }
          },

          getScanCode() {
            if (!window.Capacitor) {
              return;
            }
            var me = this;
            Capacitor.Plugins.Scanner.addListener('onScan', (data) => {
              var barcode = data.barcode;
              console.log(data.barcode);
              switch (me.focusModel) {
                case 'code' :
                  me.code = barcode;
                  var option1 = {
                    portName: 'fr.order.out.PickOrder.scanPickOrderOutStock',
                    data: {'code': me.code},
                    needLoading: false,
                    successCallback: function (data) {

                      me.pickOrderOutStockId = data.data.pickOrderOutStockId;
                      me.pickOrderId = data.data.pickOrderId;
                      me.pickOrderCode = data.data.pickOrderCode;

                      me.info = data.data.info;
                      //捡配完成
                      me.dataList1 = data.data.commuDicGeneratedList;
                      //未捡配
                      me.dataList = data.data.commuDicNotGeneratedList;
                    }
                  };
                  this.$sapi.callPort(option1);
                  break;
                case 'packageCode' :
                  /*if(isEmpty(me.pickOrderOutStockId)){
                                Toast("请先选择客户订单。");
                                return;
                            }*/
                  me.packageCode = barcode;
                  var option = {
                    portName: 'fr.order.out.PickOrder.scanPickOrderPackageCode',
                    data: {'packageCode': me.packageCode, 'pickOrderOutStockId': me.pickOrderOutStockId},
                    needLoading: false,
                    successTone: true,
                    failTone: true,
                    successCallback: function (data) {
                      var errorMessage = data.data.errorMessage;
                      if (!_.isEmpty(errorMessage)) {
                        var checkPassMsg = errorMessage + '   是否强行捡配？';
                        me.$dialog.confirm({
                          title: '',
                          message: checkPassMsg,
                        }).then(() => {
                          var option1 = {
                            portName: 'fr.order.out.PickOrder.scanPickOrderPackageCode',
                            data: {
                              'packageCode': me.packageCode,
                              'pickOrderOutStockId': me.pickOrderOutStockId,
                              'isForcedMatching': true
                            },
                            needLoading: false,
                            successTone: true,
                            failTone: true,
                            successCallback: function (data) {
                              me.info = data.data.info;
                              //捡配完成
                              me.dataList1 = data.data.commuDicGeneratedList;
                              //未捡配
                              me.dataList = data.data.commuDicNotGeneratedList;
                            }
                          };
                          me.$sapi.callPort(option1);
                        });
                        return;
                      } else {
                        me.info = data.data.info;
                        //捡配完成
                        me.dataList1 = data.data.commuDicGeneratedList;
                        //未捡配
                        me.dataList = data.data.commuDicNotGeneratedList;
                      }
                    }
                  };
                  this.$sapi.callPort(option);
                  break;
                case 'location' :
                  me.$refs.scanWin.location = barcode;
                  break;
              }
            })
          },
          submitReceive() {
            var me = this;
            var data = [];
            data['id'] = me.pickOrderId;
            var option = {
              portName: 'fr.order.out.PickOrder.checkPassPickOrder',
              data: data,
              needLoading: true,
              successCallback: function (data) {
                var checkPass = data.data.checkPass;
                if (checkPass) {
                  me.showEdit = true;
                  me.$refs.scanWin.location = me.defaultLocationCode;
                } else {
                  var checkPassMsg = data.data.checkPassMsg + '  是否继续提交？';
                  me.$dialog.confirm({
                    title: '',
                    message: checkPassMsg,
                  }).then(() => {
                    me.showEdit = true;
                    me.$refs.scanWin.location = me.defaultLocationCode;
                  });
                }
              }
            };
            this.$sapi.callPort(option);
          },
          scanCheck() {
            this.$router.push({path: 'scanCheckNew'});
          },

          confirmEdit(value) {
            var me = this;
            var data = [];
            data['location'] = value.location;
            data['id'] = me.pickOrderId;

            var option = {
              portName: 'fr.order.out.PickOrder.passPickOrderAndArea',
              data: data,
              needLoading: true,
              successCallback: function (data) {
                me.$dialog.alert({
                  title: '',
                  message: '提交成功',
                }).then(() => {
                });
              }
            };
            this.$sapi.callPort(option);
          },

          getOverallAituation() {
            var me = this;
            var option = {
              portName: 'fr.warehouse.OverallAituation.getOverallAituation',
              needLoading: true,
              successCallback: function (data) {
                if (data.entities != null && data.entities.length > 0)
                  me.defaultLocationCode = data.entities[0]["defaultOutLocationCode"];
              }
            };
            this.$sapi.callPort(option);
          }
        }
    }
</script>

<style scoped>
    .item {
        display: flex;
        border-radius: 5px;
        flex-direction: row;
        box-shadow: 0 0 2px #ddd;
        align-items: center;
        padding: 10px;
        margin: 2px;
    }

    .item + .item {
        margin-top: 10px;
    }

    .item > .content {
        flex: 1;
    }

    .item .time {
        color: #666;
    }
</style>